import { defineStore } from 'pinia'
import { getPositionType } from "@/api/saphub";
import { message } from 'ant-design-vue'
import { IPositionType } from '@/types/jobTypeSaphub'
export const useJobTypeStore = defineStore('positionType', {
  state: () => {
    return {
      positionTypeList: ref<IPositionType[]>([]),
    }
  },
  actions: {
    async getJobs() {
      const res: any = await getPositionType();
      // if (res.code !== 200) {
      //   this.positionTypeList = []
      //   return message.error('数据获取异常')
      // }
      // this.positionTypeList = res.data;

      if (res.code !== 0) {
        this.positionTypeList = []
        return message.error('数据获取异常')
      }
      this.positionTypeList = res.result;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  }
})