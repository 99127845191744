/**
 * 帖子分类
 */
import { defineStore } from 'pinia'
import { getPostCategory } from "@/api/saphub";
import { message } from 'ant-design-vue'
import { IPositionType } from '@/types/jobTypeSaphub'
export const usePostCategoryStore = defineStore('postCategory', {
  state: () => {
    return {
      postCategoryList: ref<IPositionType[]>([]),
    }
  },
  actions: {
    async getCategories() {
      const res: any = await getPostCategory();
      if (res.code !== 0) {
        this.postCategoryList = []
        return message.error('数据获取异常')
      }
      this.postCategoryList = res.result;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  }
})