import { defineStore } from 'pinia'
import { cityList } from '../../utils/city'
import { hotCityList } from '../../utils/hotCity'
import { otherCityList } from '../../utils/otherCity'
import { provinceAndCityList } from '../../utils/city'
import {selectCity} from "../../api/saphub"
import { IPositionType } from '@/types/jobTypeSaphub'
import { message } from "ant-design-vue"
export const useCity = defineStore("cities", {
  state: () => {
    return {
      preventCity: ref<String[]>([]),
      allCityList: cityList.length ? cityList : [],
      hotCityList: ref<IPositionType[]>([]),
      otherCityList: otherCityList.length ? otherCityList : [],
      provinceAndCityList: provinceAndCityList.length ? provinceAndCityList : [],
      consultCategoryData:[],
      selectedCityIds: ref<Number[]>([]),
    }
  },
  actions: {
    // async changeCity(cityName: string) {
    //   if (cityName) {
    //     this.preventCity = cityName
    //   } else {
    //     this.preventCity = '武汉'
    //   }
    // },
    async changeCity(cityIds: Array<Number>,cityNames: Array<String>) {
      if (cityIds.length > 0) {
        this.selectedCityIds = cityIds
        this.preventCity = cityNames
      }
    },
    async getCity(){
      // const hotJobList = ref<Iposition_type[]>([])
        // const res:any  =await  selectConsultCategory()
        // if(res.code!==200) {
        //   return Promise.reject(400)
        // } 
        // this.consultCategoryData = res.data
        // return Promise.resolve(200)

        const res:any  = await selectCity()
        console.log("获取城市列表");
        if(res.code!=0) {
          message.error("数据请求异常")
            this.allCityList=[]
          return
        } 
        // 这里不做处理
        // res.result.forEach((element: { children: any[] }) => {
        //   element.children.forEach(elementChild => {
        //     let index = this.selectedCityIds.indexOf(elementChild.id);
        //     if (index >= 0){ // 用本地缓存识别是否选中
        //       elementChild.checkedFlag = true
        //     } else {
        //       elementChild.checkedFlag = false
        //     }
        //   });
        // });
        console.log(res.result)
        this.allCityList=res.result
        this.hotCityList=res.result
      },
    // async setCity() {
    //   this.allCityList = cityList
    //   console.log(cityList)
    // }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  }

})